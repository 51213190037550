@import 'styles/layers.scss';

@layer uikit {
  .line {
    column-gap: 10px;
  }
  
  .label {
    opacity: 0.3;
  }
}
