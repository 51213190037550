@import 'styles/layers.scss';

@layer component {
  .wrap {
    padding: 0 20px;
  }
  
  .menu-item {
    font-size: 16px;
  }
}