@import 'styles/layers.scss';

@layer component {
  .link {
    opacity: 1;
    cursor: pointer;
    color: white;
    text-decoration: none;
    position: relative;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    &::before {
      content: "";
      position: absolute;
      background-color: var(--white);
      opacity: 0.3;
      height: 1px;
      width: 100%;
      left: 0;
      bottom: -2px;
    }
    &:hover::before {
      opacity: 1;
    }
    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
      &:hover::before {
        opacity: 0.3;
      }
    }
  }
}