.group {
  row-gap: 10px;
}

.bluebtn {
  border-radius: 6px;
}

.text {
  opacity: 0.3;
}
