@import 'styles/layers.scss';

@layer component {
  .plate {
    background-color: transparent;
  }

  .text {
    opacity: 0.3;
  }
}