@import 'styles/layers.scss';

@layer component {
  .icon {
    color: var(--white);
  }

  .btn {
    height: 25px;
    padding: 6px 9px;
    font-size: 12px;
    border-radius: 6px;
    margin-right: 8px;
  }

  .text {
    color: var(--grey8);
  }

  .icon {
    margin-right: 5px;
  }

  .active-text {
    color: var(--white);
  }
}
