@import 'styles/layers.scss';

@layer component {
  .label {
    margin-bottom: 10px;
  }
  
  .card {
    margin-bottom: 10px;
  }
  
  .error {
    border-color: var(--yellow);
  }
}