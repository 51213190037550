@import 'styles/layers.scss';

@layer component {
  .dialog {
    min-width: 525px;
  }

  .body {
    padding: 22px;
  }

  .title {
    font-size: 20px;
    text-align: center;
    margin: 0;
  }

  .title-line {
    font-size: 12px;
    margin: 16px 0 0;
    column-gap: 12px;
  }

  .marginLine {
    margin: 30px 0 20px;
  }

  .line {
    column-gap: 8px;
  }

  .label {
    color: rgba(255, 255, 255, 0.5);
  }

  .textLine {
    margin: 16px 0 30px;
  }

  .btn, .btnSave  {
    padding: 10px 18px;
  }

  .btn {
    width: 215px;
  }

  .btnSave {
    width: 71px;
    margin-top: 30px;
  }

  .settings-section {
    margin-top: 40px;
  }

  .text-storage {
    margin: 10px 0;
    font-size: 12px;
  }

  .btn-create-provider {
    width: 146px;
    height: 41px;
    margin-top: 30px;
  }

  .blockSettings {
    margin-top: 40px;
    row-gap: 40px;
  }

  .radio-group {
    row-gap: 9px;
  }

  .input-line {
    row-gap: 16px;
    margin-top: 16px;
  }

  .inputLabel {
    opacity: 0.5;
    margin-bottom: 6px;
  }

  .btnGenerateAccount {
    padding: 14px 18px;
    width: 192px;
  }

  .input-password {
    -webkit-text-security: disc;
    -moz-text-security: disc;
  }

  .img {
    margin-right: 10px;
  }

  .inputArea {
    height: 136px;
  }

  .firstSection {
    margin-bottom: 40px;
  }

  .imgIcon {
    margin-right: 4px;
  }

  .input-label {
    color: var(--white);
  }

  .link {
    color: inherit;
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
    &:hover, &:active {
      color: inherit;
    }
  }
}