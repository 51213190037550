@import 'styles/layers.scss';

@layer component {
  .block {
    margin-bottom: 30px;
  }

  .radio-label {
    font-size: 12px;
    line-height: 0.5;
  }

  .radio-title {
    margin-bottom: 10px;
  }

  .radio-container {
    display: flex;
    align-items: center;
  }

  .title-engine {
    max-width: 287px;
  }
}