@import 'styles/layers.scss';

@layer component {
  .wrapper {
    position: relative;
    width: 100%;
  }

  .wrap {
    background-color: transparent;
    padding: 26px 0 0 20px;
    width: 100%;
    &::before {
      content: "";
      position: absolute;
      height: 1px;
      width: 97.8%;
      background-color: var(--white);
      opacity: 0.1;
      top: 58px;
    }
  }
}