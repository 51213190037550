$height: 55px;

@import 'styles/layers.scss';

@layer component {
  .wrap {
    height: $height;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 100;
    left: 0;
    background: var(--black2);
  }
  
  .content {
    padding: 0 40px;
    max-width: var(--max-width);
    width: 100%;
  }
  
  .first {
    margin-left: 50px;
  }
  
  .second {
    margin-left: 35px;
  }
  
  .title {
    font-size: 16px;
  }

  .btn {
    width: 104px;
    margin-left: 40px;
    height: 40px;
  }
  
  .input {
    width: 80px;
    height: 31px;
  }
  
  .input-wrap {
    margin: 0 10px;
  }
  
  .label {
    color: var(--grey5);
  }

  .left {
    width: 575.97px;
  }

  .right {
    width: 496.47px;
  }

  .popover {
    border-radius: 6px;
    padding: 10px 14px;
  }
  
  .popoverText {
    padding: 0;
    line-height: 19.2px;
    font-weight: 300;
  }
}