@import 'styles/layers.scss';

@layer component {
  .line {
    column-gap: 10px;
  }

  .label {
    color: var(--grey5);
  }

  .blue {
    color: var(--blue1);
  }
}