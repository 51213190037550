.content {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 440px;
  color: var(--white);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%234F5159' stroke-width='1' stroke-dasharray='5%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  &.thick {
    height: 75px;
  }
  &.thin {
    height: 50px; 
  }
}

.active {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23FFF' stroke-width='1' stroke-dasharray='5%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
